.appscreen.layer0{
  z-index: 0;
  background:  center center/cover;
  
}


.appscreen.layer1{
  z-index: 1;
  background:  center center/cover;
  
}
.appscreen.layer2{
  z-index: 2;
  background:  center center/cover;
}
.appscreen.layer3{
  z-index: 3;
  background:  center center/cover;
}
.appscreen.layer4{
  z-index: 4;
  background:  center center/cover;
}
.appscreen.layer5{
  z-index: 5;
  background:  center center/cover;  
}
.appscreen.layer6{
  z-index: 6;
  background:  center center/cover;
}
.appscreen.layer7{
  z-index: 7;
  background:  center center/cover;
}

.appscreen.layer8{
  z-index: 8;
  background:  center center/cover;
}

.appscreen.layer9{
  z-index: 9;
  background:  center center/cover;
}

.appscreen.layer10{
  z-index: 10;
  background:  center center/cover;
}

.appscreen.layer11{
  z-index: 11;
  background:  center center/cover;
}




