@font-face {
  font-family: 'Boba Cups';
  src: url('https://assets.dhvij.com/fonts/BobaCups.eot');
  src: url('https://assets.dhvij.com/fonts/BobaCups.eot?#iefix') format('embedded-opentype'),
    url('https://assets.dhvij.com/fonts/BobaCups.woff2') format('woff2'),
    url('https://assets.dhvij.com/fonts/BobaCups.woff') format('woff'),
    url('https://assets.dhvij.com/fonts/BobaCups.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


@keyframes rotate {
  from {
    transform: rotate(-20deg);
  }

  to {
    transform: rotate(20deg);
  }
}


button {
  border: 0;
}

.btn {
  background: transparent;
}

.circle {
  cursor: pointer;
  text-shadow: 0 2px 0 #a59fe3, 0 0px 5px #56b4d9;
  width: 70px;
  height: 60px;
  background-size: 100% 100%;
  line-height: 80px;
  text-align: center;
  background-image: url(https://assets.dhvij.com/images/buttons/button.svg);
  font-family: 'Boba Cups';
  color: #e7e7e7;
}

.circle:hover {
  filter: hue-rotate(45deg);
}

.circle.mini {
  width: 40px;
  height: 34px;
}


.left {
  background-image: url(https://assets.dhvij.com/images/buttons/left.svg);
}

.right {
  background-image: url(https://assets.dhvij.com/images/buttons/right.svg);
}


.next {
  background-image: url(https://assets.dhvij.com/images/buttons/forward.svg);
}

.prev {
  background-image: url(https://assets.dhvij.com/images/buttons/backward.svg);
}

.restart {
  background-image: url(https://assets.dhvij.com/images/buttons/restart.svg);
  filter: hue-rotate(95deg) brightness(1.2);
}

.menu {
  background-image: url(https://assets.dhvij.com/images/buttons/menu.svg);
  filter: hue-rotate(200deg) brightness(1.2);
}


.accept {
  background-image: url(https://assets.dhvij.com/images/buttons/correct.svg);
}

.cancel {
  background-image: url(https://assets.dhvij.com/images/buttons/exit.svg);
}

.home {
  background-image: url(https://assets.dhvij.com/images/buttons/home.svg);
}

.settings {
  background-image: url(https://assets.dhvij.com/images/buttons/settings.svg);
}

.volume {
  background-image: url(https://assets.dhvij.com/images/buttons/speaker.svg);
}

.info {
  background-image: url(https://assets.dhvij.com/images/buttons/info.svg);
}


.play {
  background-image: url(https://assets.dhvij.com/images/buttons/normal/play.png);
}

.small {
  width: 204px;
  height: 80px;
  padding: 0;
  line-height: 50px;
  background-image: url(https://assets.dhvij.com/images/buttons/button-small.svg);
}

.small:hover {
  filter: hue-rotate(45deg);
}

.long {
  width: 204px;
  height: 50px;
  padding: 0;
  line-height: 50px;
  background-image: url(https://assets.dhvij.com/images/buttons/button.svg);
}


.tile {
  background-color: transparent;
  background-image: url(https://assets.dhvij.com/images/theme/1/tile.png);
  background-size: auto 100%;
  border-radius: 20px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));

}

.theme-1 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/1/tile.png);
}

.theme-2 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/2/tile.png);
}

.theme-3 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/3/tile.png);
}

.theme-4 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/4/tile.png);
}

.theme-5 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/5/tile.png);
}

.theme-6 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/6/tile.png);
}

.theme-7 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/7/tile.png);
}

.theme-8 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/8/tile.png);
}

.theme-9 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/9/tile.png);
}

.theme-10 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/10/tile.png);
}

.theme-11 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/11/tile.png);
}

.theme-12 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/12/tile.png);
}

.theme-13 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/13/tile.png);
}

.theme-14 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/14/tile.png);
}

.theme-15 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/15/tile.png);
}

.theme-16 .tile {
  background-image: url(https://assets.dhvij.com/images/theme/16/tile.png);
}


.spaceship {
  position: absolute;
  width: 300px;
  left: 0;
  top: 0;
  z-index: 20;
}

.spaceship img {
  width: 100%;
}

.circle span {
  transform: translateX(-50%);
  margin-top: 50px;
  max-width: 80vw;
  text-align: center;
  width: max-content;
  display: block;
  line-height: 40px;
}

.menu-bg {
  /* min-width: 340px;
  max-width: 50vh;
  padding: 25px;
  padding: 9vh 5vh 15vh 5vh;
  min-height: 350px;
  max-height: calc(100vh - 110px);
  height: 145vw; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 8vh 7vh 16vh 7vh;
  background: url(https://data.dhvij.com/images/frames/level-bg.svg) no-repeat center center / 100% 100%;
}

.menu-grid {
  display: flex;
  flex-direction: column;
  align-items: center;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  max-height: 100%;
}

.menu-grid>div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.menu-grid .circle {
  margin: 50px 0 30px 0;
  position: relative;
  opacity: 0;
  transform: scale(2);
  transition: all 500ms;
}

.visible .circle {
  opacity: 1;
  transform: scale(1);
}

.btn.level {
  transition: all 0.4s;
  margin: 0 auto;
  padding: 40px 0 0 0;
  color: #fff;
  font-size: 32px;
  background: url(https://assets.dhvij.com/images/frames/level-icon.svg) no-repeat 50% 30px/ contain;
  width: 65px;
  height: 130px;
}

.star-board:hover .btn {
  color: #FFD039;
  font-size: 40px;
}

.star-board:hover .stars {
  transform: scale(0.8) translateX(-50%);
}

.stars {
  position: absolute;
  top: 0%;
  left: 50%;
  transform: translateX(-50%);
  background: url(https://assets.dhvij.com/images/frames/stars_0.png) center center/cover;
  width: 100%;
  height: 0;
  padding-top: 30%;
  padding-top: 3vh;
  max-width: 80px;
  transform-origin: 0 50%;
  transition: all 0.4s;
}

.planets>div {
  perspective: 600px;
  transform-style: preserve-3d;
}

.planets>div>div {
  opacity: 0;
  transition: all 500ms;
  transform: scale(0.2) translateZ(-1300px) rotateX(45deg);
  transform-origin: 50% 50%;
}

.planets>div.visible>div {
  opacity: 1;
  transform: scale(1) translateZ(0) rotateX(0);
}

.grid .planet>img {
  position: absolute;
  top: 10%;
  left: 10%;
  width: 80%;
  height: 80%;
  animation: rotate 10s infinite linear alternate;
  transition: all 500ms;
}

.grid .planet:hover>img {
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}

.health {
  position: relative;
  max-width: 200px;
  position: absolute;
  top: 15px;
  left: 10px;
}

.health img {
  max-height: 30px;
}

.health span {
  top: 8px;
  left: 30px;
  height: 15px;
  width: 100px;
  background-color: #ccc;
  display: block;
  position: absolute;
  border-radius: 20em;
  overflow: hidden;
  border: 2px solid #fff;
}

.health span>em {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #66f4ff;
  box-shadow: 0 2px 0 2px rgba(255, 255, 255, 0.1) inset, 0 -3px 0px 2px #3f88cc inset;
  border-radius: 20em;
}



.timer {
  position: relative;
  max-width: 200px;
  position: absolute;
  top: 15px;
  right: 10px;
}

.timer img {
  width: 100%;
}

.timer span {

  font-size: 2em;
  top: 10%;
  left: 35%;
  line-height: 1.5em;
  width: 56%;
  color: #00a0e6;
  text-shadow: 1px 2px 1px rgba(0, 0, 0, .2);
  display: block;
  position: absolute;
  border-radius: 20em;
  overflow: hidden;
  font-family: 'Boba Cups';
  font-weight: normal;
  font-style: normal;
}

@keyframes slide {
  0% {
    background-position-x: 00;
  }

  100% {
    background-position-x: -800vh;
    /* background-position-x: -200vh; */
  }
}


.layer0,
.layer1,
.layer2,
.layer3,
.layer4,
.layer5,
.layer6,
.layer7,
.layer8,
.layer9,
.layer10,
.layer11 {
  background-size: 200vh !important;
}

.appscreen {

  animation: slide 4s linear infinite forwards paused;
  /* opacity: 0.95;x */

  /* filter: blur(2px); */
}


.appscreen.fg {
  z-index: 2;
  pointer-events: none;
  opacity: 1;
}

.appscreen.fg .layer0 {
  filter: saturate(1);
}



.true .appscreen {
  animation-play-state: running;
}

.layer6 {
  animation-duration: 90000ms;
}

.layer5 {
  animation-duration: 180s;
}

.layer4 {
  animation-duration: 160s;
}

.layer3 {
  animation-duration: 140s;
  /* filter: saturate(1); */
}

.layer2 {
  animation-duration: 120s;
  /* filter: saturate(0.9); */
}

.layer1 {
  animation-duration: 100s;
  /* filter: saturate(0.8); */
}

.layer0 {
  animation-duration: 30s;
  /* filter: saturate(0.7); */
}


.appStopper {
  z-index: 100;
  /* display: flex;
  flex-direction: column; */
  height: 100%;
  /* justify-content: center;
  align-items: center; */
  background: rgb(36 50 101 / 53%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
}

.dialog {
  background: url(https://assets.dhvij.com/images/frames/dialog.svg) 50% 50%/cover;
  padding: 35px 40px;
  border-radius: 0px;
  min-width: 300px;
  min-height: 370px;
  position: relative;
  max-width: 300px;
  margin: 0 auto;
  max-height: 80vh;
}

.dialog-button {
  position: absolute;
  top: 0px;
  right: -5px;
}

.dialog .small {
  width: 104px;
  height: 51px;
  line-height: 50px;
  margin-bottom: 10px;
}

.dialog .long {
  font-weight: bold;
  width: 204px;
  height: 55px;
  line-height: 55px;
  margin-bottom: 10px;
}

.settings {
  z-index: 3;
}


.app-title-container {
  max-width: 500px;
  width: 100%;
}

.app-title {
  max-width: 500px;
  width: 100%;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.4));
}


.level-bg {
  /*background: #15222B top left repeat-y fixed;*/
  padding: 0 10px;
}

.level-container {
  margin: 0 auto;
  position: relative;
  z-index: 3;

  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
}

/* .level-container > div{ margin: 0 auto; max-width: 760px;  position: relative;} */
/* .level{overflow-y: scroll; position: relative; } */
.level img,
.level svg {
  width: 100%;
  height: auto;
}

.level .btn {
  position: relative;
  line-height: 40px;
}


/* .level .btn:nth-child(1){ bottom: 10%; left: 29%;}
.level .btn:nth-child(2){ bottom: 20%; left: 8%;}
.level .btn:nth-child(3){ bottom: 30%; left: 14%;}
.level .btn:nth-child(4){ bottom: 40%; left: 85%;}
.level .btn:nth-child(5){ bottom: 50%; left: 90%;}
.level .btn:nth-child(6){ bottom: 60%; left: 24%;}
.level .btn:nth-child(7){ bottom: 70%; left: 7%;}
.level .btn:nth-child(8){ bottom: 80%; left: 23%;}
.level .btn:nth-child(9){ bottom: 90%; left: 38%;} */
.dh-grid {
  display: grid;
  grid-gap: 10px;
}

.dh-grid .dragsection>div>div {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.dh-grid .item {
  width: 100%;
}

.dh-grid.v2 {
  grid-template-columns: 1fr 1fr;
}

@media (min-width:660px) {
  .dh-grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .dh-grid .dragsection>div>div {
    display: grid;
    grid-template-columns: 1fr;
  }

  .dh-grid.v2 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media (min-width:768px) {
  .dh-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .dh-grid .dragsection>div>div {
    display: grid;
    grid-template-columns: 1fr;
  }

}



.grid {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  min-width: 100%;
}

.grid>div {
  max-width: 50vw;
  margin: 0 auto;
  height: 35vw;
}

.grid>div:nth-child(odd) {
  transform: translateX(-10vw)
}

.grid>div:nth-child(even) {
  transform: translateX(10vw)
}


.grid>div>div {
  min-width: 45vw;
  color: #fff;
  font-size: 2em;
  line-height: 1;
  position: relative;
  cursor: pointer;
  padding-top: 45vw;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  transition: all 1000ms;
}

.grid img {
  height: auto;
  max-height: 90vh;
  width: auto;
}

.grid>div>div:hover .titles {
  transform: translateY(-60%) scale(1.2);
  text-shadow: 0px 0px 0 rgb(179, 42, 181),
    0px 1px 0 rgb(174, 37, 176),
    0px 2px 0 rgb(169, 32, 171),
    0px 3px 0 rgb(164, 27, 166),
    0px 4px 0 rgb(159, 22, 161),
    0px 5px 0 rgb(154, 17, 156),
    0px 6px 0 rgb(149, 12, 151),
    0px 7px 0 rgb(144, 7, 146),
    0px 8px 0 rgb(139, 2, 141),
    0px 9px 0 rgb(134, -3, 136),
    0px 10px 0 rgb(129, -8, 131),
    0px 11px 10px rgba(0, 0, 0, 0.6),
    0px 11px 1px rgba(0, 0, 0, 0.5),
    0px 0px 10px rgba(0, 0, 0, .2);
}

.grid>div>div:hover {
  background-size: 80%;
}

.grid .titles {
  position: absolute;
  top: 50%;
  left: 10%;
  width: 80%;
  transform: translateY(-50%);
  font-size: 4vw;
  text-align: center;
  text-transform: uppercase;
  z-index: 2;
  font-family: 'Boba Cups';
  font-weight: normal;
  font-style: normal;
  transition: all 300ms ease-in-out;
  text-shadow: 0px 0px 0 rgb(49, 82, 123),
    0px 1px 0 rgb(44, 77, 118),
    0px 2px 0 rgb(39, 72, 113),
    0px 3px 0 rgb(34, 67, 108),
    0px 4px 0 rgb(29, 62, 103),
    0px 5px 0 rgb(24, 57, 98),
    0px 6px 0 rgb(19, 52, 93),
    0px 7px 0 rgb(14, 47, 88),
    0px 8px 0 rgb(9, 42, 83),
    0px 9px 0 rgb(4, 37, 78),
    0px 10px 0 rgb(-1, 32, 73),
    0px 11px 10px rgba(0, 0, 0, 0.6),
    0px 11px 1px rgba(0, 0, 0, 0.5),
    0px 0px 10px rgba(0, 0, 0, .2);


}


.drop1 {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #15222c url(https://assets.dhvij.com/images/theme/-1/2.png);
  transform: translateZ(-2000px) scale(3);
  z-index: -10;
}

.drop2 {
  position: fixed;
  width: 100%;
  left: 0%;
  height: 100%;
  background: url(https://assets.dhvij.com/images/theme/-1/3.png) 50% 0;
  transform: translateZ(-1500px) scale(2.3);
  z-index: -9;
}

.drop3 {
  position: fixed;
  width: 100%;
  left: 0%;
  height: 100%;
  background: url(https://assets.dhvij.com/images/theme/-1/4.png);
  transform: translateZ(-1000px) scale(2);
  z-index: -8;
}

@media (max-width:768px) {
  @keyframes slide {
    0% {
      background-position-x: 00;
    }

    100% {

      background-position-x: -300vh;
    }
  }

  .menu-bg {
    padding: 6vh 5vh 13vh 5vh
  }

  .grid {
    display: flex;
    flex-wrap: wrap;
    margin: 100px 0 0 0;
    padding-bottom: 100px;
  }

  .grid>div {
    transform: none !important;
    max-width: 100%;
    height: 90vw;
  }

  .grid img {
    height: auto;
    width: 100%;
  }

  .grid>div>div {
    min-width: 80vw;
    padding-top: 80vw;
    margin: 0 auto;
  }

  .grid .titles {
    font-size: 1.2em;
  }
}

html body div[data-rbd-placeholder-context-id] {
  display: none !important;
}

html body div[data-rbd-draggable-context-id]:not(.dragging) {
  transform: translate(0, 0) !important;
}


.questionindex {
  top: 48px;
  left: 10px;
  max-width: 100px;
  height: auto;
}

.questionindex img {
  max-height: 25px;
}

.questionindex span {
  top: 8px;
  left: 30px;
  height: 15px;
  width: 130px;
  background-color: #ccc;
  display: block;
  position: absolute;
  border-radius: 20em;
  overflow: hidden;
  border: 2px solid #fff;
}

.questionindex span>em {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background-color: #ffdb51;
  box-shadow: 0 2px 0 2px rgba(255, 255, 255, 0.1) inset, 0 -3px 0px 2px #ffa22c inset;
  border-radius: 20em;
  transition: all 500ms;
  ;
}

.draggable {
  touch-action: none;
}


.level .star-board .stars {
  top: 0;
  bottom: auto;
  width: 80px;
  height: 35px;
}

.star-board .stars {
  width: 100%;
  max-width: 140px;
  bottom: 0px;
  top: auto;
  padding-top: 30%;
}

.star-board .stars.r1 {
  background-image: url(https://assets.dhvij.com/images/frames/stars1.png);
}

.star-board .stars.r2 {
  background-image: url(https://assets.dhvij.com/images/frames/stars2.png);
}

.star-board .stars.r3 {
  background-image: url(https://assets.dhvij.com/images/frames/stars3.png);
}


.text {
  font-family: 'Boba Cups';
  font-size: 2em;
  color: #fff;
}

.star-board {
  padding-top: 0px;
  width: 210px;

}

.panel {
  background: url(https://assets.dhvij.com/images/frames/panel-bg.svg) center center/ 100% 100%;
  width: 100%;
  line-height: 50px;
  font-size: 18px;
  color: #fff;
}


@media (max-height:667px) and (orientation: portrait) {
  .App {
    padding: 85px 20px 7px;
  }

  .thebox.drag img {
    max-width: 70px;
  }
}

@media (max-height:414px) and (orientation: landscape) {
  .thebox.drag img {
    max-width: 70px;
  }

  .dh-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

}

@media (orientation: portrait) {

  .level-container {
    width: calc(100vw);
    height: auto;
  }

  .stage img.img-fluid {
    width: calc(100vw - 10px);
    height: auto;
  }
}

@media (orientation: portrait) and (min-width:420px) {

  .level-container {
    width: calc(100vw);
    height: auto;
  }

  .stage img.img-fluid {
    width: calc(100vw - 50px);
    height: auto;
  }


}

@media (orientation: portrait) and (min-width:520px) {

  .level-container {
    width: calc(72vw);
    height: auto;
  }

  .stage img.img-fluid {
    width: calc(100vw - 50px);
    height: auto;
  }
}


@media (orientation:landscape) {
  .level-container {
    width: calc(62vh);
    height: auto;
  }


  .stage img.img-fluid {
    height: calc(100vh - 60px);
    width: auto;
  }
}


@media (aspect-ratio: 4/3) {}