:root {
  --blue: #262261;
  --purple: #b32ab5;
}

::-webkit-scrollbar {
  display: none;
}

body {
  font-family: 'Boba Cups';
  background: #17222c;
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
  min-width: 350px;
  min-height: 350px;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}



.appbg {
  width: 100%;
  height: 100%;
  transition: 500ms transform cubic-bezier(0.65, 0.05, 0.36, 1),
    500ms box-shadow linear;

  padding: 80px 0 80px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  box-sizing: border-box;
}

.appscreen {


  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  background: center center/cover;
  pointer-events: none;
}

.open.appbg {
  transform: translate(180px, 80px);
  box-shadow: 0 0 30px -15px rgba(0, 0, 0, 0.8) inset;
}

#root {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  /*height: 100vh; */
  overflow: hidden;
}

.App {
  margin: 20px 0;
  padding: 40px 20px;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

}


.mobilenav {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  z-index: 10;
}

.mobilesettings {
  bottom: 10px;
  right: 10px;
  position: fixed;
}




.thebox {
  border: 1px solid #eee;
  border-radius: 12px;
  margin: 0px 0;
  padding: 10px 20px;
  background: #fff;
  min-height: 100px;
  line-height: 1;
  justify-content: center;
  display: flex;
  align-items: center;
}

.thebox.spell {
  padding: 0 10px;
}

.thebox img {
  aspect-ratio: 1 / 1;
  max-width: 60px;
  margin: 5px;
  transition: all 500ms;
}

.thebox.single img {
  max-width: 150px;
  width: 100%;
}

.thebox.drag {
  transition: transform 200ms, box-shadow 200ms;
  width: 100%;
  height: 100%;
}

.thebox.drag img {
  max-width: 120px;
  width: 100%;
  filter: grayscale(0.2);
  ;
}

.thebox input {
  font-weight: bold;
  text-transform: uppercase;
  max-width: 2ch;
}

.thebox.fit {
  max-width: max-content;
}

.dh-grid .item.active {
  transform: scale(1.02);
  z-index: 2;
}

.dh-grid .item.active img {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4)) brightness(1.2) contrast(1.2);

}

.dh-grid .item.active .drag {
  box-shadow: 0 0 0px 5px #025589, 0 0 20px -5px rgba(0, 0, 0, 0.8);
  box-shadow: 0 0 0px 5px #F44336, 0 0 20px -5px rgba(0, 0, 0, 0.8), 0 -8px 30px -15px #F44336 inset
}

.thebox img.active {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4)) brightness(1.2) contrast(1.2);
  transform: scale(1.1);
  ;
}

.layer-img.active {
  filter: drop-shadow(0 4px 4px rgba(0, 0, 0, 0.4)) brightness(1.2) contrast(1.2);
  transform: scale(1.1);
  ;
}

.operator {
  font-size: 10vw;
  line-height: 0.9;
  color: darkgreen;
  font-weight: 900;
  text-shadow: 2px 2px 1px #fff, 2px -2px 1px #fff, -2px 2px 1px #fff, -2px -2px 1px #fff;
}

.thebox .value {
  min-width: 2ch;
  font-weight: bold;
  font-size: 7vw;
}

.thebox.spell .value {
  font-size: 3vw;
}

.kbclicker,
.thebox input {
  font-size: 7vw;
  width: auto;
  text-align: center;
  appearance: none;
  background: transparent;
  border: 0;
  margin: 0;
  padding: 0;

}

.countimage {
  max-height: 80px;
  ;
}

.number {
  font-size: 40px;
  font-weight: 800;
}

.thebox input,
.thebox input:focus,
.thebox input:active {
  outline: 0;
  border: 0;
  appearance: button;
  -webkit-appearance: button;
  border-bottom: #282c34 1px solid;
}

.btn {
  padding: 10px 20px;
  background: #61dafb;
  margin: 5px;
  border-radius: 6px;
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'Boba Cups';
  color: #025589;
  font-size: 20px;
  font-weight: 900;
  text-align: center;
}

.head {
  color: #025589;
  font-family: 'Boba Cups';
  line-height: 1.2;
  text-shadow: 2px 2px 1px #fff, 2px -2px 1px #fff, -2px 2px 1px #fff, -2px -2px 1px #fff;
  font-size: 34px;
  font-weight: 900;
  text-align: center;
  width: 100%;
}

.pages {
  width: 100%;
  padding: 0 5px;
  position: fixed;
  bottom: 5px;
  pointer-events: none;
  z-index: 3;
}

.pages .btn {
  font-size: 2vw;
  pointer-events: all;
}

.kbclicker {
  border-bottom: 2px solid #ccc;
  width: 2ch;
  height: 100%;
}

.dragger {
  cursor: move;
}

.dragging .drag,
.dragging .dragger {
  box-shadow: 0 0 0 4px #025589;
  box-shadow: 0 0 0px 5px #F44336, 0 0 20px -5px rgba(0, 0, 0, 0.8), 0 -8px 30px -15px #F44336 inset
}

.stage {
  position: relative;
  /* border: 5px solid #fff; */
  /* background: #fff; */
  /* border-radius: 20px; */
  /* overflow: hidden; */
  /* box-shadow: 0 0 40px rgba(0, 0, 0, 0.2); */

}

.stage img.img-fluid {
  width: 100%;
  height: auto;
  pointer-events: none;

}

.stage .layer {
  position: absolute;
  z-index: 2;
  transform-origin: 0 0;
  width: auto;
  max-width: 100%;
  height: auto;
}

.stage .layer-img.drop {
  opacity: 0.4;
  filter: brightness(0);

  /* pointer-events: none; */
}

.layer.decor {
  z-index: 0;
  pointer-events: none;
}

.drag-handle {
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  height: 100%;
  transform-origin: 0 0;
  opacity: 1 !important;
}

.drag-handle[aria-pressed="true"] {
  opacity: 0.4 !important;
}

.drag-handle img {
  left: 0;
  pointer-events: none;
}

.droppable-handle {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 100%;
  transform-origin: 0 0;
}

.droppable-handle>div {
  top: 0;
  left: 0;
  width: 100%;
  position: absolute;
  height: 100%;
}

.droppable-handle img {
  left: 0;
  pointer-events: none;
}

.layer-img {
  width: 100%;
}

.btn-overlay {
  bottom: 75px;
}

@media (hover: none) {

  html,
  body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
  }
}


@media(max-width:1024px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 95%;
  }

}

@media(max-width:1024px) {

  .App>.container {
    max-width: 100%;
    padding: 0;
  }
}

@media(max-width:640px) {

  .head {
    font-size: 30px;
  }

  .thebox {
    margin: 0 0 0px 0;
  }

  .thebox img {
    max-width: 48px;
  }

  .App {
    margin: 0;
    justify-content: stretch;
    height: 100%;
  }

  .twobytwo>.d-flex {
    flex-basis: 50%;
  }

  .thebox.single img {
    max-width: 30%;
  }

  .picspell .thebox.single img {
    max-width: 100%;
  }

  .mathwithimage .thebox img {
    max-width: 45px;
    padding: 2px 2px;
    margin: 0;
  }

  .dragsection {
    flex-basis: 46%;
  }

  .thebox.spell .value {
    font-size: 5vw;
  }

  .thebox.drag img {
    max-width: 100px;
    ;
  }
}




/* tests */

.wrapper {
  transform-style: preserve-3d;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transform: rotateX(40deg);
}

.slides {
  perspective: 313px;
  padding: 50px 0px;
  width: 200%;
  height: 200%;
  transform-style: preserve-3d;
  overflow: auto;
  transform: translate(-50%, -0%);
  position: fixed;
  top: 0%;
  left: 50%;
}

.slide {
  position: relative;
  margin: auto;
  width: 600px;
  height: 400px;
  transform: rotateX(-40deg);
  transform-origin: 0 0;
  box-shadow: inset 0 0 0 5px rgba(200, 200, 200, .25);
}

.title {
  color: #fff;
  font-size: 275%;
  position: absolute;
  left: 50%;
  top: 50%;
  white-space: nowrap;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}


/* colours
  ----------------------------------------- */

.slide:nth-child(1) {
  background: hsla(0, 70%, 50%, .75);
}

.slide:nth-child(2) {
  background: hsla(45, 70%, 50%, .75);
}

.slide:nth-child(3) {
  background: hsla(90, 70%, 50%, .75);
}

.slide:nth-child(4) {
  background: hsla(135, 70%, 50%, .75);
}

.slide:nth-child(5) {
  background: hsla(180, 70%, 50%, .75);
}

.slide:nth-child(6) {
  background: hsla(215, 70%, 50%, .75);
}


.drag-grid {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 700px;
}

.drag-grid>div {
  position: relative;
}

.drag-grid>div>div {
  min-width: 150px;
  border: 0.001rem solid #eeeeee;
  width: 100%;
  overflow: hidden;
  height: 0;
  padding-top: 100%;
  position: relative;
}


.drag-grid>div:before {
  content: attr(data-id);
}

.drag-grid img {
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  height: 200%;
}

.drag-grid .dragging {
  padding-top: 0;
  background-color: #fff;
  box-shadow: 0 0 0 5px #025589;
  opacity: 0.5 !important;
}

.drag-grid .dragging-over {
  outline: 2px dashed #ff0000;
  /* Customize the outline style for the dragged-over boxes */
}



.slice_0>img {
  top: 0;
  left: 0;
}

.slice_1>img {
  top: 0;
  left: -100%;
}

.slice_2>img {
  top: -100%;
  left: 0;
}

.slice_3>img {
  top: -100%;
  left: -100%;
}


.drag-grid.g3x3 {
  grid-template-columns: 1fr 1fr 1fr;
}

.drag-grid.g3x3>div>div {
  min-width: 100px;
}

.drag-grid.g3x3 img {
  position: absolute;
  width: 300%;
  height: 300%;
}

.g3x3 .slice_0>img {
  top: 0;
  left: 0;
}

.g3x3 .slice_1>img {
  top: 0;
  left: -100%;
}

.g3x3 .slice_2>img {
  top: 0;
  left: -200%;
}

.g3x3 .slice_3>img {
  top: -100%;
  left: 0;
}

.g3x3 .slice_4>img {
  top: -100%;
  left: -100%;
}

.g3x3 .slice_5>img {
  top: -100%;
  left: -200%;
}

.g3x3 .slice_6>img {
  top: -200%;
  left: 0;
}

.g3x3 .slice_7>img {
  top: -200%;
  left: -100%;
}

.g3x3 .slice_8>img {
  top: -200%;
  left: -200%;
}

/* Debugging
  ----------------------------------------- */
.body {
  margin: 0;
  padding: 0;
  perspective: 400px;
  overflow: hidden;
  background: #252825;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 99.99%;
  /* Should be `0` but Safari needs this! (BUG #1) */
  right: 0;
  height: 100%;
}

label {
  font: 100%/1.5 Arial;
  background: #fff;
  padding: .25em .5em;
  border-radius: 3px;
  position: fixed;
  z-index: 1;
  margin: .5em
}

.wrapper {
  transition: .5s transform;
}

.slide {
  transition: .5s 1s transform;
}

.slides {
  box-shadow: 0 0 0 1px #0c0;
  transition: .5s 2s width;
}

body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

h1 {
  text-align: center;
}

.grid-item {
  padding: 16px;
  margin: 0;
  min-height: 40px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
}